import ApplicationController from '../../../javascript/controllers/application_controller'
import Trix from 'trix'

export default class extends ApplicationController {

  connect() {
    super.connect();
    /// we have to wait until the trix-editor-wrapper is available, else the folder button will not be shown when a new learn_unit is added to the phase (rendering triggered by Reflex)
    setTimeout(() => {
      this.initializeTrixEditor();
    }, 100);
  }

  initializeTrixEditor() {
    // Show Media Library button, when the trix-editor-wrapper is available and not suppress_media_library is set
    const trixMediaLibraryButton = $(this.element).find("button.trix-media-library-button");
    let journeyWrapper = $("#journey_edit_form_wrapper");
    if(journeyWrapper.length == 0) {
      journeyWrapper = $("#journey_builder");
    }
    // console.log("this.element: ", this.element);
    // console.log('journeyWrapper: ', journeyWrapper);
    // console.log('trixMediaLibraryButton: ', trixMediaLibraryButton);
    // console.log('suppress media library: ', journeyWrapper.data('suppress-media-library'));
    if (trixMediaLibraryButton.length && !journeyWrapper.data('suppress-media-library')) {
      // console.log('trix-editor-wrapper found');
      // console.log("element: ", this.element);
      trixMediaLibraryButton.removeClass('visually-hidden');
      // console.log("trixMediaLibraryButton: ", trixMediaLibraryButton);
      return;
    }
  }

  /**
   * opens the media library modal
   * @param {Event} event
   */
  openMediaLibrary(event) {
    event.preventDefault();
    // console.log('Opening Media Library');
    const $target = $(event.currentTarget);
    const $mediaLibraryModal = $('#media_library_modal');
    const trixEditorWrapper = $target.closest('.trix-editor-wrapper');
    const journeyId = $('#journey_builder').data('journey-id') || $('#journey_edit_form_wrapper').data('journey-id');
    const editorId = trixEditorWrapper.attr('id');
    this.stimulate("MediaLibraryReflex#render_trix_editor_modal", event.currentTarget, editorId, journeyId, null).then(payload => {
      // open media library modal
      $mediaLibraryModal.modal('show');
    });
  }

  /**
   * renders the media library
   * @param {Event} event
   */
  renderMediaLibrary(event) {
    event.preventDefault();
    // console.log('Rendering Media Library');
    const $target = $(event.currentTarget);
    const entriesWrapper = $target.closest('.entries-wrapper.media-library');
    const folder_name = $target.data('folder-name');
    // console.log('folder_name: ', folder_name);
    const journeyId = $('#journey_builder').data('journey-id') || $('#journey_edit_form_wrapper').data('journey-id');
    // console.log('journey id: ', journeyId);
    const editorId = entriesWrapper.data('editor-id');
    // console.log('editor id: ', editorId);
    this.stimulate("MediaLibraryReflex#render_trix_editor_modal", event.target, editorId, journeyId, folder_name).then(payload => {
    });
  }

  attachMediaLibraryEntry(event) {
    event.preventDefault();

    const $target = $(event.currentTarget);
    const entry = event.currentTarget;
    // console.log('entry: ', entry);
    const entryUrl = entry.dataset.url;
    // console.log('entryUrl: ', entryUrl);
    const entryName = entry.dataset.name;
    // Fügen Sie den ausgewählten MediaLibraryEntry in den Trix-Editor ein
    const entriesWrapper = $target.closest('.entries-wrapper');
    // console.log('entries Wrapper: ', $(entriesWrapper));
    const editorId = `#${entriesWrapper.data('editor-id')}`;
    // console.log('editorId: ', editorId);
    const editor = $(editorId).find('trix-editor')[0].editor;
    // console.log('editor: ',  editor);
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    // console.log('baseUrl: ', baseUrl);
    // fetch(entryUrl)
    fetch(`${baseUrl}/${entryUrl}`)
    .then(response => response.blob())
    .then(blob => {
      const file = new File([blob], entryName, { type: blob.type });
      editor.insertFile(file);
    })
    .catch(error => console.error('Error:', error));

    // Schließen Sie das Modal
    $('#media_library_modal').modal('hide');
  }
}
