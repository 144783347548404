import { Color } from '@svgdotjs/svg.js';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["value", "label"];

  connect() {
    this.element.addEventListener('changed-color', (event) => {
      const color = event.detail.color;

      $(this.valueTarget).css({
      "background-color": color,
      });
      const wrapper = this.valueTarget.closest('.color-wrapper').find("input"); //.val(color);
      console.log("wrapper: ", wrapper);
      $(this.labelTarget).html(color);
    });
  }

  change(event) {
    let $target = $(event.currentTarget),
    color = $target.val();

    $(this.valueTarget).css({
      "background-color": color,
    });

    $(this.labelTarget).html(color);
  }

}