import ApplicationController from './application_controller';

export default class extends ApplicationController {

  /**
   * Toggles the view type based on the clicked element's data attribute and updates the window location.
   *
   * @param {Event} event - The event triggered by clicking the element.
   */
    toggleView(event) {
      const target = $(event.currentTarget)
      var view_type = target.data('view-type')
      const url = new URL(window.location);
      url.searchParams.set('view_type', view_type);
      if (view_type == '2' || view_type == '3') {
        url.searchParams.delete('page');
      }
      url.searchParams.set('view_type', view_type);
      // window.history.pushState({}, '', url);
      window.location = url;
    }
}