import Ajax from '../../packs/ajax'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ["hiddenField"]

  async translate(event) {
    const url = this.element.dataset.url
    const targetLang = event.target.dataset.targetLang
    const fieldsToTranslate = $('#default_translations input[type=hidden]:not([untranslatable]), #default_translations input[type=text]:not([untranslatable]), #default_translations textarea:not([untranslatable])')
    // console.log("fields to translate: ", fieldsToTranslate)
    const textToTranslate = fieldsToTranslate.map((index, field) => $(field).val()).toArray()
    const response = await new Ajax(url).doAjax('POST', { 'text': textToTranslate, 'target_lang': targetLang })
    const translations = response.translations
    // console.log("translations: ", translations)
    if(translations.length > 0) {
      const self = this
      fieldsToTranslate.each((index, originField) => {
        if(index < translations.length) {
          let attribute = $(originField).attr('deepl_attribute')
          if(attribute === undefined) {
            //lets try to find the trix editor for this
            // self.logInfo(`search for trix editor: ${originField.id}`)
            attribute = $(`#default_translations [input="${originField.id}"]`).attr('deepl_attribute')
          }
          // self.logInfo(`attribute: ${attribute}`)
          const text = translations[index].text
          // self.logInfo(`text: ${text}`)
          const field = $(`#translation_${targetLang} [deepl_attribute="${attribute}"]`)
          // self.logInfo("field: ", field)
          // This is a trix editor so we need to do some extra stuff
          if(field.attr('input') !== undefined) {
            field.html(text)
            const hiddenField = $(`#${field.attr('input')}`)
            hiddenField.val(text)
          } else {
            field.val(text)
          }
        }
      })
    } else {
      // Assuming that there has been an error.
      // disable the deepl_translate buttons
      $('[data-action="click->translation--deepl#translate"]').addClass('disabled')
      // show alert message
      $('#deepl_info').removeClass('d-none').addClass('show')
    }

  }

  updateHiddenField(event) {
    // event.preventDefault()
    const $target = $(event.currentTarget)
    const $checkedInput = $target
    const optionIndex = $checkedInput.val()
    const options = {left: true, right: false}
    const currentMode = options[optionIndex]
    if(this.hasHiddenFieldTarget) {
      $(this.hiddenFieldTarget).val(currentMode)
    }
  }
}