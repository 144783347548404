import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  initialize() {
    super.initialize()
    $(this.element).modal('show')
  }

  ///
  // disables the submit button after the message is sent
  ///
  message_sent(event) {
    // console.log('Message sent')

     event.currentTarget.disabled = true
  }
}