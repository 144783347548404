import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["datetimestring", "timestring", "datestring"]

  static values = {
    locale: String,
    defaultlocale: String,
    environment: String
  }

  initialize() {
    super.initialize();
    // initialize I18n-js
    this.useReflex = false;
    this.initializeI18n();

    // Date/time initialization
    window.datetime = {
      format: I18n.t('javascript.format.both'),
      formatTime: I18n.t('javascript.format.time'),
      formatDate: I18n.t('javascript.format.date'),
      toLocal: function(date) {
        return moment.utc(date, moment.iso8601, true).local().format(this.format)
      },
      toLocalDate: function(date) {
        return moment.utc(date, moment.iso8601, true).local().format(this.formatDate)
      },
      toLocalTime: function(date) {
        return moment.utc(date, moment.iso8601, true).local().format(this.formatTime)
      },
      toUTC: function(date) {
        return moment(date, this.format, true).utc().format(moment.iso8601)
      }
    }
    console.log("datetime initialized", window.datetime)
    this.tooltipList = []

  }

  // here all the things are initialized (after page load), which are needed on every page
  connect() {
    super.connect();
    // add autoclose for notice alerts
    const autoCloseAlert = function(time) {
      setTimeout(function(){
        $('.alert.alert-primary').fadeOut('slow');
      }, time);
    }
  
    autoCloseAlert(2500); // Close after 2,5 seconds

    // initialize tooltips
    this.setupTooltips();
  }

  datetimestringTargetConnected(el) {
    $(el).text(datetime.toLocal($(el).attr("data-value")))
  }

  datestringTargetConnected(el) {
    $(el).text(datetime.toLocalDate($(el).attr("data-value")))
  }

  timestringTargetConnected(el) {
    $(el).text(datetime.toLocalTime($(el).attr("data-value")))
  }

  initializeI18n() {
    I18n.defaultLocale = this.defaultlocaleValue
    I18n.locale = this.localeValue
    let locale = I18nLocales[this.localeValue]
    I18n.store(locale)
  }

  ///
  // sets up the bs tooltip toggle
  ///
  setupTooltips() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, { boundary: "window" })
    })
    console.log(`setup ${this.tooltipList.length} tooltips`)
  }

  ///
  // hides all tooltips on page
  ///
  hideTooltips() {
    console.log(`hide ${this.tooltipList.length} tooltips`)
    this.tooltipList.map(function (tooltip) {
      tooltip.hide();
    });

  }

  ///
  // destroys all tooltips on page
  ///
  destroyTooltips() {
    this.tooltipList.map(function (tooltip) {
      // console.log("destroy tooltip: ", tooltip)
      try {
        tooltip.dispose();
      } catch (error) {
        // console.error("Error disposing tooltip:", error);
      }
    });
    console.log(`destroyed ${this.tooltipList.length} tooltips`)
    this.tooltipList = [];
  }

  ///
  // sets up the bs tooltip toggle again
  // should be called after reflex morphs
  // be sure they have been destroyed befor the reflex was called
  ///
  reloadTooltips() {
    console.log("reload tooltips")
    // this.destroyTooltips();
    this.setupTooltips();
  }

}
