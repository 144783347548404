{
  "en": {
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "dict": {
      "active": "active",
      "adapt": "adapt",
      "address": "address",
      "agreements": "Agreements",
      "all": "all",
      "answer": {
        "one": "answer",
        "other": "answers"
      },
      "appointment": {
        "one": "appointment",
        "other": "appointments"
      },
      "back": "back",
      "blog_entries": "learn modules",
      "cancel": "cancel",
      "certificate": {
        "one": "certificate",
        "other": "certificates"
      },
      "change": "Change",
      "clone": "Clone",
      "close": "Close",
      "confirm": "confirm",
      "contact_people": "contact people",
      "continue": "continue",
      "correct": "correct",
      "create": "Create",
      "current": "current",
      "day": "day",
      "de": "German",
      "delete": "delete",
      "description": "Description",
      "development_path": "Development Path",
      "edit": "Edit",
      "email": "email",
      "empty": "No entries present",
      "en": "English",
      "es": "Spanish",
      "event": {
        "one": "event",
        "other": "events"
      },
      "folder": {
        "one": "Folder",
        "other": "Folders"
      },
      "fr": "French",
      "full_name": "name",
      "gtc": "General terms and conditions",
      "hero_slider_elements": "banner",
      "hide": "Hide",
      "hour": "hour",
      "hu": "Hungarian",
      "in_timezone": "Timezone %{timezone}",
      "inactive": "inactive",
      "index": "index",
      "interaction_needed": "action needed",
      "invoice": "invoice",
      "ja": "Japanese",
      "journey": {
        "one": "Journey",
        "other": "Journeys"
      },
      "language": {
        "one": "Language",
        "other": "Languages"
      },
      "learn_card": {
        "index": "Learn Card No. %{index}"
      },
      "learn_path": "learning path",
      "learn_paths": "learning paths",
      "learn_unit": {
        "one": "Learning Unit",
        "other": "Learning Units"
      },
      "locations": "locations",
      "mandatory_field": "Mandatory field",
      "messages": {
        "no_further_learn_units_on_modules": "No further learning units can be added if the phase contains a module.",
        "no_module_on_non_empty_phases": "No modules can be added if the phase is not empty.",
        "read": "read",
        "unread": "unread"
      },
      "minute": "minute",
      "module": {
        "one": "Module",
        "other": "Modules"
      },
      "month": "month",
      "name": {
        "one": "name",
        "other": "names"
      },
      "nano_certificate": "nano certificate",
      "nano_certificates": "nano certificates",
      "no_data": "no data available",
      "open": "Open",
      "order": "order",
      "parent": "parent",
      "participants": "participants",
      "permission": {
        "one": "permission",
        "other": "permissions"
      },
      "phase": {
        "one": "Learning Phase",
        "other": "Learning Phases"
      },
      "phone_number": "phone number",
      "point": {
        "one": "point",
        "other": "points"
      },
      "primary": "Primary",
      "private": "Private",
      "product": "product",
      "products": "products",
      "profile": {
        "one": "profile",
        "other": "profiles"
      },
      "project_experience": "Project Experience",
      "question": {
        "one": "question",
        "other": "questions"
      },
      "reminders": "Reminders",
      "restart": "restart",
      "result": {
        "one": "result",
        "other": "results"
      },
      "salutations": {
        "company": "Company",
        "madame": "Misses",
        "sir": "Mister"
      },
      "save": "Save",
      "scope_tag": {
        "one": "scope tag",
        "other": "scope tags"
      },
      "search_profile": "search profile",
      "second": "second",
      "secondary": "Secondary",
      "send": "send",
      "show": "Show",
      "shown_from_short": "from",
      "shown_until_short": "until",
      "silent": "deactivated",
      "skill": {
        "one": "skill",
        "other": "skills"
      },
      "skill_category": {
        "one": "skill category",
        "other": "skill categories"
      },
      "skill_rating": {
        "one": "skill rating",
        "other": "skill ratings"
      },
      "slide": {
        "index": "Slide No. %{index}"
      },
      "statistics": "statistics",
      "stop": "stop",
      "supervised_people": "supervised people",
      "supervised_person": "supervised person",
      "take": "take",
      "template_new": "Create Template",
      "tenant": {
        "one": "tenant",
        "other": "tenants"
      },
      "tenant_scope": {
        "one": "tenant scope",
        "other": "tenant scopes"
      },
      "themes": "learn pathes",
      "translations": "translations",
      "users": "users",
      "vat": "VAT",
      "view_all": "view all",
      "week": "week",
      "withdrawal": "Withdrawal",
      "year": "year"
    },
    "javascript": {
      "format": {
        "both": "DD. MMM YYYY HH:mm",
        "date": "DD. MMM YYYY",
        "time": "HH:mm"
      }
    },
    "shared": {
      "choose_category": "choose category...",
      "choose_subcategory": "choose subcategory...",
      "delete_group_definitions_confirm": "You are about to delete the group definition. Are you sure you want to do that?",
      "done": "done",
      "email": "email",
      "week": "Week"
    }
  }
}