import { Controller } from '@hotwired/stimulus';

export default class ChatController extends Controller {

  static targets = ['container', 'item', 'participants'];

  connect() {
    this.scrollToBottom();
    if (this.hasContainerTarget) {
      let self = this;
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('chat-item')) {
            // console.log('New message added');
            self.onNodeAdded(self, { target: node });
          }
        });
          }
        }
      });

      observer.observe(this.containerTarget, { childList: true, subtree: true });
    }
  }

  toggleParticipants(event) {
    if(this.hasParticipantsTarget) {
      $(this.participantsTarget).toggleClass('closed');
    }
  }


  onNodeAdded(self, event) {
    // Check if a new message was added
    if($(event.target).hasClass('chat-item')) {
      self.scrollToBottom('smooth');
    }
  }
  
  // itemTargetDisconnected(element) {
  //   console.log(element);
  // }

  scrollToBottom(behavior = 'auto') {
    if (this.hasContainerTarget) {
      this.containerTarget.scrollTo({
        top: this.containerTarget.scrollHeight,
        behavior: behavior
      });
    }
  }
}