{
  "de": {
    "date": {
      "abbr_day_names": [
        "So",
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez"
      ],
      "day_names": [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag"
      ],
      "formats": {
        "default": "%d.%m.%Y",
        "long": "%e. %B %Y",
        "short": "%e. %b"
      },
      "month_names": [
        null,
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "dict": {
      "active": "aktiv",
      "adapt": "anpassen",
      "address": "Adresse",
      "agreements": "Vereinbarungen",
      "all": "Alle",
      "answer": {
        "one": "Antwort",
        "other": "Antworten"
      },
      "appointment": {
        "one": "Termin",
        "other": "Termine"
      },
      "back": "zurück",
      "blog_entries": "Lern-Module",
      "cancel": "abbrechen",
      "certificate": {
        "one": "Zertifikat",
        "other": "Zertifikate"
      },
      "change": "Ändern",
      "clone": "Kopieren",
      "close": "Schließen",
      "confirm": "bestätigen",
      "contact_people": "Kontaktpersonen",
      "continue": "weiter",
      "correct": "korrekt",
      "create": "Anlegen",
      "current": "Aktuell",
      "day": "Tag",
      "de": "Deutsch",
      "delete": "Löschen",
      "description": "Beschreibung",
      "development_path": "Entwicklungs-Pfad",
      "edit": "Bearbeiten",
      "email": "E-Mail",
      "empty": "keine Einträge vorhanden",
      "en": "Englisch",
      "es": "Spanisch",
      "event": {
        "one": "Veranstaltung",
        "other": "Veranstaltungen"
      },
      "folder": {
        "one": "Ordner",
        "other": "Ordner"
      },
      "fr": "Französisch",
      "full_name": "Name",
      "gtc": "Allgemeine Geschäftsbedingungen",
      "hero_slider_elements": "Banner",
      "hide": "Verbergen",
      "hour": "Stunden",
      "hu": "Ungarisch",
      "in_timezone": "Zeitzone %{timezone}",
      "inactive": "inaktiv",
      "index": "Reihenfolge",
      "interaction_needed": "Aktion erforderlich",
      "invoice": "Rechnung",
      "ja": "Japanisch",
      "journey": {
        "one": "Journey",
        "other": "Journeys"
      },
      "language": {
        "one": "Sprache",
        "other": "Sprachen"
      },
      "learn_card": {
        "index": "Lernkarte Nr. %{index}"
      },
      "learn_path": "Lernpfad",
      "learn_paths": "Lernpfade",
      "learn_unit": {
        "one": "Lerneinheit",
        "other": "Lerneinheiten"
      },
      "locations": "Lokationen",
      "mandatory_field": "Pflichtfeld",
      "messages": {
        "no_further_learn_units_on_modules": "Es können keine weiteren Lerneinheiten hinzugefügt werden, wenn die Phase ein Modul enthält.",
        "no_module_on_non_empty_phases": "Es können keine Module hinzugefügt werden, wenn die Phase nicht leer ist.",
        "read": "gelesen",
        "unread": "ungelesen"
      },
      "minute": "Minute",
      "module": {
        "one": "Modul",
        "other": "Module"
      },
      "month": "Monat",
      "name": {
        "one": "Name",
        "other": "Namen"
      },
      "nano_certificate": "Nano-Zertifikat",
      "nano_certificates": "Nano-Zertifikate",
      "no_data": "keine Daten vorhanden",
      "open": "Öffnen",
      "order": "Reihenfolge",
      "parent": "Elter",
      "participants": "Teilnehmer",
      "permission": {
        "one": "Berechtigung",
        "other": "Berechtigungen"
      },
      "phase": {
        "one": "Lernphase",
        "other": "Lernphasen"
      },
      "phone_number": "Telefonnummer",
      "point": {
        "one": "Punkt",
        "other": "Punkte"
      },
      "primary": "Primär",
      "private": "Privat",
      "product": "Produkt",
      "products": "Produkte",
      "profile": {
        "one": "Profil",
        "other": "Profile"
      },
      "project_experience": "Projekterfahrung",
      "question": {
        "one": "Frage",
        "other": "Fragen"
      },
      "reminders": "Erinnerungen",
      "restart": "neu starten",
      "result": {
        "one": "Ergebnis",
        "other": "Ergebnisse"
      },
      "salutations": {
        "company": "Firma",
        "madame": "Frau",
        "sir": "Herr"
      },
      "save": "Speichern",
      "scope_tag": {
        "one": "Bereichs-Tag",
        "other": "Bereichs-Tags"
      },
      "search_profile": "Such-Profil",
      "second": "Sekunde",
      "secondary": "Sekundär",
      "send": "abschicken",
      "show": "Anzeigen",
      "shown_from_short": "ab",
      "shown_until_short": "bis",
      "silent": "deaktiviert",
      "skill": {
        "one": "Skill",
        "other": "Skills"
      },
      "skill_category": {
        "one": "Skill-Kategorie",
        "other": "Skill-Kategorien"
      },
      "skill_rating": {
        "one": "Skill-Bewertung",
        "other": "Skill-Bewertungen"
      },
      "slide": {
        "index": "Slide Nr. %{index}"
      },
      "statistics": "Statistiken",
      "stop": "stoppen",
      "supervised_people": "Zugeordnete Personen",
      "supervised_person": "Zugeordnete Person",
      "take": "übernehmen",
      "template_new": "Vorlage anlegen",
      "tenant": {
        "one": "Mandant",
        "other": "Mandanten"
      },
      "tenant_scope": {
        "one": "Mandanten-Scope",
        "other": "Mandanten-Scopes"
      },
      "themes": "Lernpfade",
      "translations": "Übersetzungen",
      "users": "Benutzer",
      "vat": "USt.-ID",
      "view_all": "Alle sehen",
      "week": "Woche",
      "withdrawal": "Rücktritt",
      "year": "Jahr"
    },
    "javascript": {
      "format": {
        "both": "DD.MM.YYYY HH:mm",
        "date": "DD.MM.YYYY",
        "time": "HH:mm"
      }
    },
    "shared": {
      "choose_category": "Kategorie wählen...",
      "choose_subcategory": "Sub-Kategorie wählen...",
      "delete_group_definitions_confirm": "Du bist dabei, die Gruppen-Definition zu löschen. Bist du sicher, dass du das tun möchtest?",
      "done": "erledigt",
      "email": "E-Mail",
      "week": "Woche"
    }
  }
}