{
  "hu": {
    "date": {
      "abbr_day_names": [
        "Va",
        "Hé",
        "Ke",
        "Sze",
        "Cs",
        "Pé",
        "Szo"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Már",
        "Ápr",
        "Máj",
        "Jún",
        "Júl",
        "Aug",
        "Sze",
        "Okt",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Vasárnap",
        "Hétfő",
        "Kedd",
        "Szerda",
        "Csütörtök",
        "Péntek",
        "Szombat"
      ],
      "formats": {
        "default": "%Y.%m.%d",
        "long": "%Y %B %e.",
        "short": "%b. %e."
      },
      "month_names": [
        null,
        "Január",
        "Február",
        "Március",
        "Április",
        "Május",
        "Június",
        "Július",
        "Augusztus",
        "Szeptember",
        "Október",
        "November",
        "December"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "dict": {
      "active": "aktív",
      "adapt": "alkalmaz",
      "address": "cím",
      "agreements": "Megállapodások",
      "all": "összes",
      "answer": {
        "one": "válasz",
        "other": "válaszok"
      },
      "appointment": {
        "one": "találkozó",
        "other": "találkozók"
      },
      "back": "vissza",
      "blog_entries": "tanulmányok",
      "cancel": "Mégse",
      "certificate": {
        "one": "bizonyítvány",
        "other": "bizonyítványok"
      },
      "change": "Módosítás",
      "clone": "Klónozás",
      "close": "Bezárás",
      "confirm": "megerősítés",
      "contact_people": "kapcsolattartók",
      "continue": "folytatás",
      "correct": "helyes",
      "create": "Létrehozás",
      "current": "jelenlegi",
      "day": "nap",
      "de": "Német",
      "delete": "Törlés",
      "description": "Leírás",
      "development_path": "Fejlesztési útvonal",
      "edit": "Szerkesztés",
      "email": "e-mail",
      "empty": "Nincs bejegyzés jelen",
      "en": "Angol",
      "es": "Spanyol",
      "event": {
        "one": "esemény",
        "other": "események"
      },
      "folder": {
        "one": "Mappa",
        "other": "Mappák"
      },
      "fr": "Francia",
      "full_name": "név",
      "gtc": "Általános szerződési feltételek",
      "hero_slider_elements": "banner",
      "hide": "Elrejtés",
      "hour": "óra",
      "hu": "Magyar",
      "in_timezone": "Időzóna %{timezone}",
      "inactive": "inaktív",
      "index": "index",
      "interaction_needed": "teendő",
      "invoice": "számla",
      "ja": "Japán",
      "journey": {
        "one": "Út",
        "other": "Utak"
      },
      "language": {
        "one": "Nyelv",
        "other": "Nyelvek"
      },
      "learn_card": {
        "index": "Tanulókártya sz. %{index}"
      },
      "learn_path": "tanulási útvonal",
      "learn_paths": "tanulási útvonalak",
      "learn_unit": {
        "one": "Tanulási egység",
        "other": "Tanulási egységek"
      },
      "locations": "helyszínek",
      "mandatory_field": "Kötelező mező",
      "messages": {
        "read": "olvasott",
        "unread": "olvasatlan"
      },
      "minute": "perc",
      "module": {
        "one": "Modul",
        "other": "Modulok"
      },
      "month": "hónap",
      "name": {
        "one": "név",
        "other": "nevek"
      },
      "nano_certificate": "nano tanúsítvány",
      "nano_certificates": "nano tanúsítványok",
      "no_data": "nincs elérhető adat",
      "open": "Nyitva",
      "order": "rend",
      "parent": "szülő",
      "participants": "résztvevők",
      "permission": {
        "one": "jogosultság",
        "other": "jogosultságok"
      },
      "phase": {
        "one": "Tanulási fázis",
        "other": "Tanulási fázisok"
      },
      "phone_number": "telefonszám",
      "point": {
        "one": "pont",
        "other": "pontok"
      },
      "primary": "Elsődleges",
      "private": "Magán",
      "product": "termék",
      "products": "termékek",
      "profile": {
        "one": "profil",
        "other": "profillal"
      },
      "project_experience": "Projekt tapasztalat",
      "question": {
        "one": "kérdés",
        "other": "kérdések"
      },
      "restart": "újraindítás",
      "result": {
        "one": "eredmény",
        "other": "eredmények"
      },
      "salutations": {
        "company": "Cég",
        "madame": "Hölgy",
        "sir": "Úr"
      },
      "save": "Mentés",
      "scope_tag": {
        "one": "hatáskör címke",
        "other": "hatáskör címkék"
      },
      "search_profile": "keresési profil",
      "second": "másodperc",
      "secondary": "Másodlagos",
      "send": "küldés",
      "show": "Megjelenítés",
      "shown_from_short": "-tól",
      "shown_until_short": "-ig",
      "silent": "deaktivált",
      "skill": {
        "one": "képesség",
        "other": "képességek"
      },
      "skill_category": {
        "one": "képesség kategória",
        "other": "képesség kategóriák"
      },
      "skill_rating": {
        "one": "képesség értékelés",
        "other": "képesség értékelések"
      },
      "slide": {
        "index": "Diavetítés sz. %{index}"
      },
      "statistics": "statisztika",
      "stop": "Megállítás",
      "supervised_people": "felügyelt személyek",
      "supervised_person": "felügyelt személy",
      "take": "fog",
      "template_new": "Sablon létrehozása",
      "tenant": {
        "one": "bérlő",
        "other": "bérlők"
      },
      "tenant_scope": {
        "one": "bérlő hatáskör",
        "other": "bérlő hatáskörök"
      },
      "themes": "tanulási utak",
      "translations": "fordítások",
      "users": "felhasználók",
      "vat": "ÁFA",
      "view_all": "összes megjelenítése",
      "week": "hét",
      "withdrawal": "Visszavonás",
      "year": "év"
    },
    "javascript": {
      "format": {
        "both": "YYYY.MM.DD HH:mm",
        "date": "YYYY.MM.DD",
        "time": "HH:mm"
      }
    },
    "shared": {
      "choose_category": "válassz kategóriát...",
      "choose_subcategory": "válassz alkategóriát...",
      "delete_group_definitions_confirm": "Biztosan törölni szeretnéd a csoport definíciót?",
      "done": "kész",
      "email": "e-mail",
      "week": "Hét"
    }
  }
}